import { ThemeOverride, extendTheme } from '@chakra-ui/react';

import { Heading, Button, Divider, Checkbox, Text, Switch } from './override';

const baseTheme: ThemeOverride = {
  config: {
    cssVarPrefix: 'www-ui',
  },
  colors: {
    cyan: {
      600: '#6CBBD9',
      700: '#4C87B9',
    },
    blue: {
      600: '#25478F',
      800: '#0E2645',
    },
    gray: {
      50: '#E9EEF2',
      100: '#CBD9E2',
      550: '#A1A1A9',
    },
    orange: {
      300: '#EBB02C',
      600: '#EB782D',
    },
    zmyle: {
      primary: '#E83675',
      secondary: '#c02169',
    },
    store: {
      primary: '#e98623',
      secondary: '#e9ba00',
    },
    network: {
      primary: '#244a8f',
      secondary: '#69BBD9',
    },
    benefit: {
      primary: '#594f99',
      secondary: '#924f8d',
    },
  },
  components: {
    Heading,
    Button,
    Divider,
    Checkbox,
    Switch,
    Text,
  },
  fonts: {
    heading: 'var(--font-roboto-slab)',
    body: 'var(--font-roboto)',
  },
  styles: {
    global: {
      body: {
        fontSize: '1.125rem',
        bg: 'white',
        color: 'blue.800',
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
      },
      '*::selection': {
        bg: 'zmyle.primary',
        color: 'white',
      },
    },
  },
  breakpoints: {
    sm: '50rem',
    md: '64rem',
    lg: '90rem',
    xl: '120rem',
  },
};

export const theme = extendTheme(baseTheme);

export default theme;
